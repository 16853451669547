import SideBar from "../components/sidebar/sidebar";
import "../assets/css/layout.css";
function Layout(props){
return(
    <div className="container-fluid">
    <div className="row flex-nowrap"> 
    <SideBar />
       <div className="col-auto col-sm-9 col-md-10 col-lg-10 p-0 m-0">
        {props.children}
        </div> 
    </div>
    </div>
)
}
export default Layout;