import { Route, Routes, BrowserRouter, redirect  } from "react-router-dom";
import './App.css';
import './assets/css/btn.css'
import './assets/css/ui.css'
import ProtectedRoutes from './utils/ProtectedRoutes';

// import dotenv from 'dotenv';
import Login from "./views/login/login";
import Users from "./views/users/users";
import Annonces from "./views/annonces/list";
import DetailsAnnonce from "./views/annonces/details";
import DetailsUser from "./views/users/details";

import Dashboard from "./views/dashboard";
import Notifs from "./views/notifs/list";
// dotenv.config();

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
        
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />  
            <Route element={<ProtectedRoutes role="" />}>
              <Route path="/users" element={<Users />} />
              <Route path="/users/:id" element={<DetailsUser />} />

              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/annonces" element={<Annonces />} />
              <Route path="/annonces/:id" element={<DetailsAnnonce />} />
              <Route path="/notifs" element={<Notifs />} />

            </Route>
          </Routes>
      </BrowserRouter>
      </div>
  );
}

export default App;
