import axios from "axios";
const API_URL = process.env.REACT_APP_URL_API || ''
console.log("api",API_URL)

const token = localStorage.getItem('token')
//console.log('token', token)
const headersFiles = {
    headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    },
};
 
const getAll = (endpoint,token) => {
    // console.log('end point', API_URL + endpoint)   
     return axios.get(API_URL + endpoint,{
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    });
};
const getAllAdmin = (endpoint,token) => {
    // console.log('end point', API_URL + endpoint)   
     return axios.get(API_URL + endpoint,{
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    })
};
const getAllByData = (endpoint,data) => {
    
    return axios.post(API_URL + endpoint,data);
};
const getOne = (id, endpoint) => {
    return axios.get(API_URL +  endpoint + `/${id}`, headersFiles);
};

const create = (data, endpoint) => {
    return axios.post(API_URL +  endpoint, data, headersFiles);
};

const deleteByid = (id, endpoint) => {
    return axios.delete(API_URL + endpoint + `/${id}`, headersFiles);
};

const update = (data, id, endpoint) => {
    return axios.post(API_URL + endpoint + `/update/${id}`, data, headersFiles);
};
const patch = (data, id, endpoint) => {
    return axios.patch(API_URL + endpoint + `/update/${id}`, data, headersFiles);
};
const archived = (id, endpoint) => {
    return axios.patch(API_URL + endpoint + `/${id}`, headersFiles);
};
const updatewithoutID = (data, endpoint) => {
    return axios.post(API_URL + endpoint + `/update`, data, headersFiles);
};
const getByRole = (endpoint, role) => {
    
    return axios.get(API_URL + endpoint+ `/${role}`, headersFiles);
};


const Services = {

    patch,archived, getAll,getOne,create,deleteByid,update, updatewithoutID,getByRole,getAllByData,getAllAdmin

}
export default Services;
